.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.app-container {
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden; /* Optional: to prevent horizontal scrolling if content exceeds screen width */
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.custom-swal {
  background-color: #1c1e23;
  color: #fff;
}
.fv-plugins-message-container {
  font-size: 0.85rem;
}
.form-control {
  &::placeholder {
    color: #a3a3a3;
  }

  &:focus {
    background-color: #1c1e23;
    color: #fff;
  }
  background-color: #1c1e23;
  border-color: #a3a3a3;
  color: #fff;
}
.phone-input {
  height: 35 !important;
  width: 100% !important;
  background-color: #1c1e23 !important;
  border: 1px solid grey !important;
  border-radius: 10 !important;
  box-sizing: border-box !important;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  color: white;
}
.shadow-lg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.each-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.each-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* or "cover" depending on your preference */
}
.otp-field {
  width: 2.5rem !important;
  height: 3rem;
  font-size: 1.2rem;
  &::-moz-selection {
    background: #1c1e23;
    color: white;
  }
  &::selection {
    background: #1c1e23;
    color: white;
  }
}

.currency-icon {
  width: 25px;
  height: 25px;
}

.dropdown-menu {
  background-color: #1c1e23;
  .dropdown-item {
    color: #eeeaff;
    &.active {
      background-color: #1c1e23;
    }
    &:hover {
      background-color: #1c1e23;
    }
  }
}
.flag-dropdown {
  background-color: #1c1e23 !important;
  color: #fff;
  .selected-flag {
    background-color: #1c1e23 !important;
  }
  &:hover {
    background-color: #1c1e23 !important;
  }
  .country-list {
    background-color: #1c1e23 !important;
    .country {
      &:hover {
        background-color: #1c1e23 !important;
      }
      &.highlight {
        background-color: #1c1e23 !important;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: rgba(28, 30, 35, 0.2);
  }
  100% {
    background-color: rgba(28, 30, 35, 0.85);
  }
}
.text-muted {
  color: #a3a3a3 !important;
}
.swal2-toast {
  background-color: #2e323a !important;
  text-align: center;
}
.bank-active,
.point-shop-item {
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2),
    0 6px 20px 0 rgba(255, 255, 255, 0.19);
}
.switch {
  &.active {
    color: #080808;
    background: #f8c006;
  }
  &:hover {
    color: #fff;
    background-color: rgba(245, 190, 9, 0.5);
  }
}
.lottery-nav {
  border-bottom: 2px solid transparent;
  &.active {
    color: #f8c006;
    border-bottom: 2px solid #f8c006;
  }

  &:hover {
    border-bottom: 2px solid #c39e27;
  }
}

.lottery-head-cashweep {
  background-color: #408c04;
}

.lottery-head-damacai {
  background-color: #fff;
  color: #080808;
}

.lottery-head-gdlotto {
  background-color: #000;
  color: #f8c006;
}

.lottery-head-magnum {
  background-color: #ffc40c;
  color: #080808;
}

.lottery-head-sabah88 {
  background-color: #ffbcbc;
  color: #080808;
}

.lottery-head-sandakan {
  background-color: #10ac44;
}

.lottery-head-singapore {
  color: #fff;
  background-color: #000;
}

.lottery-head-toto {
  background-color: #ff0404;
}

.bg-prim {
  background-color: #ffa500 !important;
}

.bg-hover {
  background-color: #aaff0012;
}

.ws-nowrap {
  white-space: nowrap;
}

.custom-border {
  border: 1px solid #a3a3a3;
}

.border-prim {
  border: 1px solid #f8c0066c;
}

.custom-border-bottom {
  border-bottom: 1px solid #a3a3a3;
}

.custom-border-end {
  border-right: 1px solid #a3a3a3;
}

.btn-prim {
  background-color: #f5be09;
  color: #080808;
  padding: 0.5rem;
  &:hover {
    background-color: #f5be09;
    color: #080808;
  }
  &:disabled {
    background-color: #dcab08;
    color: #080808;
  }
}

.btn-prim-light {
  background-color: rgba(245, 190, 9, 0.05);
  color: #f5be09;
  border: 1px solid #f5be09;
  padding: 0.5rem;
  &:hover {
    background-color: #f5be09;
    color: #080808;
  }
  &:disabled {
    background-color: #dcab08;
    color: #080808;
  }
}

.btn-custom-light {
  background-color: #fff;
  color: #080808;
  padding: 0.5rem;
  &:hover {
    background-color: #fff;
    color: #080808;
  }
}

.bg-light-success {
  background-color: #d1ffbd !important;
}

.lottery-slip-item {
  &:hover {
    i {
      color: #ffa500;
    }
  }
}

.spinning-animation {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Adjust the number of lines as needed */
}

@media (max-width: 767px) {
  .truncate {
    -webkit-line-clamp: 1; /* Adjust the number of lines for mobile view */
  }
}

.text-font1 {
  font-size: 10px;
}
.text-font2 {
  font-size: 12px;
}
.text-font3 {
  font-size: 13px;
}
.text-font4 {
  font-size: 14px;
}
.floating-buttons-container {
  position: fixed;
  z-index: 1000;
}

.floating-button {
  position: absolute;
  display: none;
}

.visible {
  display: inline-block;
}

.hidden {
  display: none;
}
.dialogComing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogComing-content {
  background-color: #1c1e23;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  color: white;
}

.custom-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scroll-y-default {
  overflow-y: auto;
}

.pages-bg {
  background-size: cover;
}

.swal2-html-container {
  white-space: pre-line;
}
